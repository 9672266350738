import React from "react";

import styles from "./CardPackage.module.css";
import { PrimaryButton } from "../Button/Button";
import useStore from "../../store/modalSubs";
import ModalSubscribe from "../Modal/ModalSubscribe";

export default function CardPackage({ data }) {
  const setModal = useStore((state) => state.setIsModal);
  const modal = useStore((state) => state.isModal);
  if (modal) {
    return <ModalSubscribe />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <h1>{data.package_name}</h1>
        <p>{data.price}</p>
        <p className={styles.accountProject}>
          {data.number_of_account} - {data.topic_setup}
        </p>
      </div>
      <div className={styles.benefitContainer}>
        <div>
          {data.benefit.map((item, i) => (
            <div key={i} className={styles.listBenefit}>
              <img src={item.icon} alt="icon" />
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bonusContainer}>
        <p className={styles.bonusTitle}>Bonus</p>
        <ul className={styles.listContainer}>
          {data.bonus.map((item, i) => (
            <li className={styles.listBonus} key={i}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.buttonContainer}>
        <PrimaryButton onClick={() => setModal()}>Choose Plan</PrimaryButton>
      </div>
    </div>
  );
}
