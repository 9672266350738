import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./register.module.css";
import swal from "sweetalert";

import AuthService from "../../services/auth.services";
import { PrimaryButton } from "../../components/Button/Button";

function Register() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordAgainRef = useRef();

  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const username = usernameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passwordAgain = passwordAgainRef.current.value;
    if (username.length < 6) {
      swal({
        text: `Username minimal 6 karakter`,
        icon: "error",
        buttons: true,
      });
      return;
    }
    if (password.length < 6) {
      swal({
        text: `Password minimal 6 karakter`,
        icon: "error",
        buttons: true,
      });
      return;
    }
    if (password !== passwordAgain) {
      swal({
        text: `Password pertama dan kedua harus sama. Cek password lagi!`,
        icon: "error",
        buttons: true,
      });
      return;
    }
    AuthService.register(username, email, password)
      .then((resp) => {
        swal({
          text: `${resp.data.data.email} berhasil didaftarkan!`,
          buttons: true,
          icon: "success",
          timer: 2000,
        });
        history.push("/signin");
      })
      .catch((error) => {
        swal({
          text: error.response.data.error,
          buttons: true,
          icon: "error",
          timer: 5000,
        });
      });
  };

  return (
    <div className={styles.wrapperRegister}>
      <div className={styles.containerRegister}>
        <div className={styles.iconUser}>
          <Link to="/">
            <img src="assets/suar.svg" alt="suar" />
          </Link>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={styles.inputGroup}>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Username"
                ref={usernameRef}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputWrapper}>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                ref={emailRef}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputWrapper}>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputWrapper}>
              <input
                type="password"
                name="passwordAgain"
                id="passwordAgain"
                placeholder="Password Again"
                ref={passwordAgainRef}
              />
            </div>
          </div>
          <PrimaryButton type="submit">Register</PrimaryButton>
        </form>
        <p>
          Have an account? <Link to="/signin">Login!</Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
