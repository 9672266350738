import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

import styles from "./Profile.module.css";
import instance from "../../services/axios";
import AuthService from "../../services/auth.services";
import { PrimaryButton } from "../../components/Button/Button";

export default function Profile() {
  const currentUser = AuthService.getCurrentUser();
  const history = useHistory();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    oldPassword: "",
    newPassword: "",
  });
  const [data, setData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post(
        "api/v1/update-profile-user",
        {
          name: formData.username,
          old_password: formData.oldPassword,
          new_password: formData.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${currentUser.success.token}` },
        }
      );
      swal({
        text: response.data.status,
        icon: "success",
        buttons: true,
      });
      history.push("/monitoring");
    } catch (error) {
      swal({
        text: error.response.data.error.name[0],
        icon: "error",
        buttons: true,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("api/v1/profile-user", {
          headers: { Authorization: `Bearer ${currentUser.success.token}` },
        });
        if (response.data.data) {
          setFormData((prevState) => ({
            ...prevState,
            username: response.data.data.name || "",
            email: response.data.data.email || "",
          }));
        }
        setData(response.data.data);
      } catch (error) {
        console.error("Error Fetching profile", error);
      }
    };
    fetchData();
  }, [currentUser.success.token]);

  return (
    <div className={styles.container}>
      <div className={styles.profileContainer}>
        <div className={styles.headingProfileContainer}>
          <h3>Profile</h3>
          <p>Total Project: {data?.total_project}</p>
          <p>Subscription Detail: {data?.subscription_detail}</p>
          <p>Start Date Subscription: {data?.start_date_subscription}</p>
          <p>End Date Subscription: {data?.end_date_subscription}</p>
        </div>
        <form onSubmit={handleSubmit} className={styles.editProfileForm}>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="username"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="email"
                disabled
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="oldPassword">Old Password</label>
              <input
                type="password"
                id="oldPassword"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
                placeholder="Old Password"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="New Password"
              />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <PrimaryButton type="submit">Save</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
}
