import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";

import styles from "./login.module.css";
import AuthService from "../../services/auth.services";
import { PrimaryButton } from "../../components/Button/Button";

function Login({ setIsToken }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    // console.log(emailRef.current.value, "email");
    // console.log(passwordRef.current.value, "password");
    AuthService.login(email, password)
      .then((resp) => {
        localStorage.setItem("user", JSON.stringify(resp.data));

        swal({
          text: `Email ${email} loggin successful`,
          icon: "success",
          timer: 1000,
          buttons: false,
        });

        history.push("/monitoring");

        setIsToken(true);
      })
      .catch((err) =>
        swal({
          text: err.response.data.msg,
          icon: "error",
          timer: 5000,
          buttons: true,
        })
      );
  };

  return (
    <div className={styles.wrapper_login}>
      <img
        className={styles.bg_image}
        src="assets/bg-login.png"
        alt=""
        width="800px"
      />
      <div className={styles.container_login}>
        <div className={styles.suar_logo}>
          <Link to="/">
            <img src="assets/suar.svg" alt="User" />
          </Link>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={styles.input_group}>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              ref={emailRef}
            />
          </div>
          <div className={styles.input_group}>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              ref={passwordRef}
            />
          </div>
          <PrimaryButton type="submit">Login</PrimaryButton>
        </form>
        <p>
          Don't have an account? <Link to="/signup">Register!</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
