import { Close } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import swal from "sweetalert";

import convertImage from "../../helpers/base64";
import AuthService from "../../services/auth.services";
import useStore from "../../store";
import instance from "../../services/axios";
import styles from "./Modal.module.css";
import { PrimaryButton } from "../Button/Button";

function Modal() {
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const setModal = useStore((state) => state.setIsModal);

  const namaRef = useRef();
  const titleRef = useRef();
  const gambarRef = useRef();
  const currentUser = AuthService.getCurrentUser();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePostCalon = async (e) => {
    e.preventDefault();
    const nama = namaRef.current.value;
    const title = titleRef.current.value;
    const gambar = URL.createObjectURL(gambarRef.current.files[0]);
    const hasilConvert = await convertImage(gambar);
    const dataReturnPost = await instance.post(
      "api/usermonitor/set",
      {
        name: nama,
        title: title,
        image: hasilConvert,
      },
      {
        headers: { Authorization: `Bearer ${currentUser.success.token}` },
      }
    );
    setModal();
    swal({
      text: dataReturnPost.data?.msg,
      icon: "success",
      timer: 5000,
      buttons: false,
    });
  };

  return (
    <div className={styles.modal_container}>
      <div className={styles.container_form}>
        <div className={styles.icon_close_modal} onClick={() => setModal()}>
          <Close />
        </div>
        <div>
          <form onSubmit={handlePostCalon}>
            <div className={styles.project_image_container}>
              <div className={styles.project_image}>
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Project Preview"
                    width="134px"
                    height="134px"
                    className={styles.image}
                  />
                )}
              </div>
              <div>
                <label htmlFor="foto-calon">Project Image</label>
                <input
                  type="file"
                  id="foto-calon"
                  className={styles.input_gambar_calon}
                  name="foto-calon"
                  ref={gambarRef}
                  onChange={handleImageChange}
                  required
                />
              </div>
            </div>
            <label htmlFor="title-calon">Project Title</label>
            <input
              type="text"
              id="title-calon"
              className={styles.input_calon}
              ref={titleRef}
              required
            />
            <label htmlFor="nama-calon">Keyword</label>
            <textarea
              id="nama-calon"
              className={styles.textarea_custom}
              ref={namaRef}
              required
            />
            <div className={styles.btn_cari_container}>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Modal;
