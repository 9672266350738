import React from "react";
import { Link } from "react-router-dom";

// import ReactDatePicker from "react-datepicker";
// import AuthService from "../../services/auth.services";
// import { addDays } from "date-fns";
import styles from "./footer.module.css";

function Footer({ startDate, setStartDate, endDate, setEndDate }) {
  // const currentUser = AuthService.getCurrentUser();
  //   // DATE PICKER
  //   const [startDate, setStartDate] = useState("");
  //   const [endDate, setEndDate] = useState("");
  //   console.log("endDate di footer :>> ", endDate);
  return (
    <div className={styles.container_footer}>
      <div className={styles.divider}></div>
      <div className={styles.wrapper_footer}>
        <div className={styles.tradmark}>
          <div className={styles.suar_logo}>
            <Link to="/">
              <img src="assets/suar.svg" alt="logo" width="140px" />
            </Link>
            <p>
              Stay Up-to-Date, Analisa, dan Tingkatkan <br />
              Brand Awareness serta Personal Branding Anda <br />
              dengan <a href="/">suar.tms.id</a>
            </p>
          </div>
          <div className={styles.center_footer}>
            <span>Contact Us</span>
            <span>Pricing</span>
            <span>About Us</span>
          </div>
          <div className={styles.center_footer}>
            <span>FAQs</span>
            <span>How To</span>
            <span>2024 - Suar by TMS All Rights Reserved</span>
          </div>
        </div>
      </div>
      {/* <img src="assets/suar-tms-putih.png" alt="logo-suar" width="110px" /> */}
      {/* <div className="data-stream">
      {currentUser && currentUser.success.role_id === 3 && (
        <>
          <span>DATA STREAM START: </span>
          <div className="date-picker-input">
            <ReactDatePicker
              selected={
                startDate ? startDate : new Date().setHours(0, 0, 0)
              }
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={addDays(new Date(endDate), -180)}
              maxDate={new Date()}
            />
          </div>
          <span>-</span>
          <div className="date-picker-input">
            <ReactDatePicker
              selected={endDate ? endDate : new Date()}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={addDays(new Date(startDate), 1)}
              maxDate={addDays(new Date(startDate), 180)}
            />
          </div>
        </>
      )}
    </div> */}
    </div>
  );
}

export default Footer;
