import { Close } from "@mui/icons-material";
import React, { useRef } from "react";
import swal from "sweetalert";
import instance from "../../services/axios";
import useStore from "../../store/modalSubs";
// import { useWindow } from "../../helpers/useWindow";
import "./modal.css";
import { PrimaryButton } from "../Button/Button";

function Modal() {
  const setModal = useStore((state) => state.setIsModal);

  const namaRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const packageRef = useRef();
  // let layer = useWindow();

  // let imagePackage;
  // if (layer >= 768) {
  //   imagePackage = "./assets/subscribe-package.png";
  // } else {
  //   imagePackage = "./assets/mobile-subscribe.png";
  // }

  const handleSubscribe = (e) => {
    e.preventDefault();
    const nama = namaRef.current.value;
    const email = emailRef.current.value;
    const no_hp = phoneRef.current.value;
    const paket = packageRef.current.value;

    instance
      .post("api/v1/subscribe", {
        nama,
        email,
        no_hp,
        paket,
      })
      .then((res) => {
        swal({
          text: `Thank you for your request, we will contact you soon! ${res.data.msg}`,
          buttons: true,
          icon: "success",
          timer: 2000,
        });
      })
      .catch((error) => {
        swal({
          text: `${error} | Gagal Subscribe`,
          buttons: true,
          icon: "error",
          timer: 5000,
        });
      })
      .finally(() => {
        setModal();
      });
  };

  return (
    <div className="subscribe-modal-container">
      <div className="subscribe-container-form">
        <div className="subscribe-icon-close-modal" onClick={() => setModal()}>
          <Close />
        </div>
        <div className="subscribe-title-container">
          {/* <div className="subscribe-circle"></div> */}
          <div>
            <span>
              Untuk Subscribe terkait Analisis dan Monitoring Data <br />{" "}
              Silahkan isi form di bawah ini!
            </span>
          </div>
        </div>
        {/* <div className="subscribe-package">
          <img src={imagePackage} alt="" />
        </div> */}
        <form onSubmit={handleSubscribe}>
          <div className="group-control">
            <input
              type="text"
              id="nama-subscriber"
              className="input-subscriber"
              ref={namaRef}
              placeholder="Name"
              required
            />
            <input
              type="text"
              id="email-subscriber"
              className="input-subscriber"
              ref={emailRef}
              placeholder="Email"
              required
            />
          </div>
          <div className="group-control">
            <input
              type="number"
              id="nohp-subscriber"
              className="input-subscriber"
              ref={phoneRef}
              placeholder="Phone No"
              required
            />
            <select ref={packageRef} className="sel-option-subscriber">
              <option value="personal">Personal</option>
              <option value="agency">Agency</option>
              <option value="enterprise">Enterprise</option>
              <option value="white label">White Label</option>
            </select>
          </div>
          <div className="subs-btn-container">
            {/* <button type="submit" className="btn-go">
              Lets Go!
            </button> */}
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
