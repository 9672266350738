import React from "react";
import {
  ArticleOutlined,
  FacebookRounded,
  Instagram,
  Twitter,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import "./CardCalon.css";

function CardCalon({ isLoading, setMedsos, medsos, dataCalon, image, nama }) {
  return (
    <div className="card-person">
      <div className="header-person-container">
        <div className="title-person">
          <img src={image} alt={nama} />
        </div>
        <div className="wrapper-name">
          <span>{nama}</span>
        </div>
      </div>
      <div className="contents-person">
        <div className="social-media">
          <div
            className={
              medsos === "article" ? "icon-sosial active" : "icon-sosial"
            }
            onClick={() => setMedsos("article")}
          >
            <ArticleOutlined fontSize={"medium"} />
          </div>
          <div
            className={medsos === "fb" ? "icon-sosial active" : "icon-sosial"}
            onClick={() => setMedsos("fb")}
          >
            <FacebookRounded fontSize={"medium"} />
          </div>
          <div
            className={
              medsos === "twitter" ? "icon-sosial active" : "icon-sosial"
            }
            onClick={() => setMedsos("twitter")}
          >
            <Twitter fontSize={"medium"} />
          </div>
          <div
            className={medsos === "ig" ? "icon-sosial active" : "icon-sosial"}
            onClick={() => setMedsos("ig")}
          >
            <Instagram fontSize={"medium"} />
          </div>
        </div>
        <div className="numfound-wrapper">
          <div className="numfound">
            {dataCalon[3] && dataCalon[3].numFound}
          </div>
          <div className="numfound">
            {dataCalon[2] && dataCalon[2].numFound}
          </div>
          <div className="numfound">
            {dataCalon[0] && dataCalon[0].numFound}
          </div>
          <div className="numfound">
            {dataCalon[1] && dataCalon[1].numFound}
          </div>
        </div>
        <div className="content-article">
          {isLoading && (
            <div className="isLoading">
              <CircularProgress size={80} />
            </div>
          )}

          {dataCalon[3] &&
            medsos === "article" &&
            dataCalon[3].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className="article">{item.content}</div>
              </a>
            ))}
          {dataCalon[2] &&
            medsos === "fb" &&
            dataCalon[2].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className="article">{item.content}</div>
              </a>
            ))}
          {dataCalon[1] &&
            medsos === "ig" &&
            dataCalon[1].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className="article">{item.content}</div>
              </a>
            ))}
          {dataCalon[0] &&
            medsos === "twitter" &&
            dataCalon[0].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className="article">{item.content}</div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CardCalon;
