import React from "react";
import "./Button.css";

const PrimaryButton = ({ children, ...props }) => (
  <button className="button primary-button" {...props}>
    {children}
  </button>
);

const SecondaryButtonContained = ({ children, ...props }) => (
  <button className="button secondary-contained-button" {...props}>
    {children}
  </button>
);

const SecondaryButtonOutlined = ({ children, ...props }) => (
  <button className="button secondary-outlined-button" {...props}>
    {children}
  </button>
);

export { PrimaryButton, SecondaryButtonContained, SecondaryButtonOutlined };
