import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

// import GoogleTrends from "../helpers/GoogleTrends";
// import { countDensity } from "../helpers/countDensity";
import CardCalon from "../components/CardCalon/CardCalon";
import instance from "../services/axios";
import { PrimaryButton } from "../components/Button/Button";
import { packageData } from "../data/packageData";
import CardPackage from "../components/CardPackage/CardPackage";
import AuthService from "../services/auth.services";

function Home() {
  //  TIME INTERVAL => DETIK
  const detik = 15;
  const [dataDefault, setDataDefault] = useState([]);
  const [medsos, setMedsos] = useState("article");
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = AuthService.getCurrentUser();

  // const [density, setDensity] = useState([]);
  // let dataMedia = "";
  // for (let i = 0; i < dataDefault[0]?.user_data[0]?.data.length; i++) {
  //   dataMedia += dataDefault[0]?.user_data[0]?.data[i].content;
  // }
  // console.log(dataMedia, "dataMedia");
  // console.log(countDensity(dataMedia), "content");
  // setDensity(countDensity(dataMedia));
  // console.log(dataPrabowo[3], "dataPrabowo[3]");
  // CONFIG TANGGAL
  // const hariIni = new Date();
  // const newTahun = hariIni.getFullYear();
  // const newBulan = hariIni.getMonth() + 1;
  // const newTanggal = hariIni.getDate();

  // GET API DEFAULT
  useEffect(() => {
    setIsLoading(true);
    const fetchDataDefault = async () => {
      const dataDefault = await instance.get("api/dmonitor");
      setDataDefault(dataDefault.data);
      setIsLoading(false);

      // Density Word
      // let dataMedia = "";
      // for (let i = 0; i < dataDefault.data[0]?.user_data[0]?.data.length; i++) {
      //   dataMedia += dataDefault.data[0]?.user_data[0]?.data[i].content;
      // }
      // setDensity(countDensity(dataMedia));
    };

    fetchDataDefault();

    // setelah beberapa detik
    const timer = setInterval(() => {
      setIsLoading(true);
      fetchDataDefault();
    }, detik * 1000);

    // hapus interval
    return () => {
      clearInterval(timer);
    };
  }, [setIsLoading]);

  return (
    <div className="main-container">
      <div className="hero-container">
        <h1>It's Happening?</h1>
        <p>
          Suar akan membantu suatu brand untuk menemukan macam-macam tren <br />
          dan meningkatkan performance media sosialmu melalui monitoring.
        </p>
        {!currentUser && (
          <Link to="/signup" className="menu-item">
            <span title="login">
              <PrimaryButton>Sign up free</PrimaryButton>
            </span>
          </Link>
        )}

        <img
          src="assets/hero-frame.png"
          alt="Hero Frame"
          className="image-frame"
        />
      </div>
      <div className="container-person">
        {/* <div className="navbar-stardate">
        <h3>
          {`Data Stream Start:
          ${newTahun}-${newBulan}-${newTanggal} 00:00:01`}
        </h3>
      </div> */}
        <div className="wrapper-person">
          {dataDefault.length > 0 ? (
            dataDefault.map((data, idx) => (
              <CardCalon
                key={idx}
                isLoading={isLoading}
                setMedsos={setMedsos}
                medsos={medsos}
                dataCalon={data?.user_data}
                image={`data:image/jpeg;base64, ${data?.profile_link}`}
                nama={data?.profile_title}
              />
            ))
          ) : (
            <div className="loading-circle">
              <CircularProgress size={100} />
            </div>
          )}
        </div>
        {/* Google Trends */}
        {/* <div id="widget">
        <div className="wrapper-name" style={{ textAlign: "center" }}>
          <h4>GOOGLE TRENDS</h4>
        </div>
        <GoogleTrends
          type="dailytrends"
          url="https://ssl.gstatic.com/trends_nrtr/2674_RC03/embed_loader.js"
        />
      </div> */}
        {/* {density.length > 0 && (
        <div id="widget">
          <div className="wrapper-name" style={{ textAlign: "center" }}>
            <h4>Twitter - Prabowo</h4>
          </div>
          {density.map((item, idx) => (
            <div className="density-wrapper" key={idx}>
              <div className="title-density">{item[0]}</div>
              <div className="count-density">{item[1]}</div>
            </div>
          ))}
        </div>
      )} */}
      </div>
      <div className="client-container">
        <img src="assets/bw-idscore.png" alt="logo-pkt" />
        <img src="assets/bw-pkt.png" alt="logo-pkt" />
        <img src="assets/bw-detik.png" alt="logo-detik" />
        <img src="assets/bw-tms.png" alt="logo-tms" />
      </div>
      <div className="center-words-container">
        <img
          className="image-frame-feature"
          src="assets/center-frame.png"
          alt="frame"
        />
        <div className="heading-words">
          <h1>
            Cari tau apa yang sedang viral di <br />
            media sosial menggunakan satu platform
          </h1>
          <p className="top-text">
            Jelajahi setiap detail performance media sosial dengan SUAR
          </p>
          <div className="list-item-feature">
            <div className="list-item">
              <img
                src="assets/mata.png"
                alt="realtime-monitoring"
                width="27px"
              />
              <div>
                <p>Real-time monitoring</p>
                <p>Memantau isi media sosial secara real time.</p>
              </div>
            </div>
            <div className="list-item">
              <img
                src="assets/petir.png"
                alt="dashboard-analytics"
                width="27px"
              />
              <div>
                <p>Dashboard Analytics</p>
                <p>
                  Memudahkan suatu brand untuk mengumpulkan, <br />{" "}
                  menganalisis, dan menyajikan data media sosial.
                </p>
              </div>
            </div>
            <div className="list-item">
              <img src="assets/papan.png" alt="email-alert" width="27px" />
              <div>
                <p>Email Alert</p>
                <p>Reminder your campaign performance.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="package-container">
        {packageData.map((item, i) => (
          <CardPackage data={item} key={i} />
        ))}
      </div>
    </div>
  );
}

export default Home;
