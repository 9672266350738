import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { createTheme, ThemeProvider } from "@mui/material";

import AuthService from "../../../services/auth.services";
import useStoreListUser from "../../../store/listUser";
import instance from "../../../services/axios";
import "./ModalListUser.css";
import GenerateTableData from "./GenerateTableData";
import { PrimaryButton } from "../../Button/Button";

function ModalListUser() {
  const currentUser = AuthService.getCurrentUser();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const setModalListUser = useStoreListUser(
    (state) => state.setIsModalListUser
  );

  const columns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "status_user", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const name = nameRef.current.value;
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      const res = await instance.post(
        "api/v1/userview/register",
        {
          name,
          email,
          password,
        },
        {
          headers: { Authorization: `Bearer ${currentUser.success.token}` },
        }
      );
      setModalListUser();
      swal({
        text: res.data?.msg,
        icon: "success",
        timer: 5000,
        buttons: false,
      });
    } catch (error) {
      setError(error.response);
    }
  };

  const fetchListUser = useCallback(async () => {
    try {
      const res = await instance.get("/api/v1/userview/list", {
        headers: {
          Authorization: `Bearer ${currentUser.success.token}`,
        },
      });
      setUsers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [currentUser.success.token]);

  const handleActiveInactive = useMemo(
    () => async (id) => {
      try {
        await instance.get(`/api/v1/userview/status/${id}`, {
          headers: { Authorization: `Bearer ${currentUser.success.token}` },
        });
        fetchListUser();
      } catch (error) {
        console.log(error);
      }
    },
    [currentUser.success.token, fetchListUser]
  );

  const deleteUser = useMemo(
    () => async (id) => {
      swal(`Yakin mau dihapus?`, {
        dangerMode: true,
        buttons: true,
      }).then((result) => {
        if (result) {
          instance
            .get(`api/v1/userview/delete/${id}`, {
              headers: {
                Authorization: `Bearer ${currentUser.success.token}`,
              },
            })
            .then((resp) => {
              swal({
                text: resp.data.data,
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              setModalListUser();
            });
        }
      });
    },
    [currentUser.success.token, setModalListUser]
  );

  const tableData = useMemo(
    () => GenerateTableData(users, handleActiveInactive, deleteUser),
    [users, handleActiveInactive, deleteUser]
  );

  useEffect(() => {
    fetchListUser();
  }, [fetchListUser]);

  return (
    <div className="modal-container">
      <div className="container-form">
        <div className="icon-close-modal" onClick={() => setModalListUser()}>
          <Close />
        </div>
        <div className="content-container">
          <div className="list-title-container">
            <ThemeProvider theme={darkTheme}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Add User</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {error && (
                    <span style={{ color: "red" }}>{error.data.error}</span>
                  )}
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="nama-calon">Nama</label>
                    <input
                      type="text"
                      id="nama-calon"
                      className="input-calon"
                      ref={nameRef}
                      required
                    />
                    <label htmlFor="email-calon">Email</label>
                    <input
                      type="text"
                      id="email-calon"
                      className="input-calon"
                      ref={emailRef}
                      required
                    />
                    <label htmlFor="password-calon">Password</label>
                    <input
                      type="password"
                      id="password-calon"
                      className="input-calon"
                      ref={passwordRef}
                      required
                    />
                    <div className="btn-cari-container">
                      <PrimaryButton type="submit">Save</PrimaryButton>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          </div>
        </div>
        <ThemeProvider theme={darkTheme}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell key={i} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data) => {
                    return (
                      <TableRow key={data.id}>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.email}</TableCell>
                        <TableCell>{data.status}</TableCell>
                        <TableCell>{data.action}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default ModalListUser;
