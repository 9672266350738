import React from "react";
import swal from "sweetalert";
import {
  ArticleOutlined,
  FacebookRounded,
  Instagram,
  Twitter,
  Remove,
  Edit,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import AuthService from "../../services/auth.services";
import useStore from "../../store";
import ModalEdit from "../Modal/ModalEdit";
import instance from "../../services/axios";
import styles from "./CardCalonMember.module.css";

function CardCalonMember({
  isLoading,
  setMedsos,
  medsos,
  dataCalon,
  image,
  nama,
  title,
  profilId,
}) {
  // TOKEN
  const currentUser = AuthService.getCurrentUser();

  // Store
  const modal = useStore((state) => state.isModalEdit);
  const setModal = useStore((state) => state.setIsModalEdit);

  // HAPUS CALON
  const hapusCalon = (e) => {
    e.preventDefault();

    swal(`Yakin ${title} mau dihapus?`, {
      dangerMode: true,
      buttons: true,
    }).then((result) => {
      if (result) {
        instance
          .delete(`api/usermonitor/delete/${profilId}`, {
            headers: {
              Authorization: `Bearer ${currentUser.success.token}`,
            },
          })
          .then((resp) => {
            swal({
              text: resp.data.msg,
              icon: "success",
              buttons: false,
              timer: 5000,
            });
          });
      }
    });
  };

  // Modal
  if (modal) {
    return <ModalEdit />;
  }

  return (
    <div className={styles.card_person}>
      <div className={styles.header_person_container}>
        <div className={styles.title_person}>
          <img src={image} alt={nama} />
          {currentUser.success.role_id !== 5 && (
            <>
              <div
                className={styles.icon_edit}
                title="Edit?"
                onClick={() => setModal({ title, nama, image, profilId })}
              >
                <Edit fontSize="normal" />
              </div>
              <div
                className={styles.icon_remove}
                title="Hapus?"
                onClick={hapusCalon}
              >
                <Remove />
              </div>
            </>
          )}
        </div>
        <div className={styles.wrapper_name}>
          <h4>{nama}</h4>
        </div>
      </div>
      <div className={styles.contents_person}>
        <div className={styles.social_media}>
          {dataCalon[3] && (
            <div
              className={
                medsos === "article" ? "icon-sosial active" : "icon-sosial"
              }
              onClick={() => setMedsos("article")}
            >
              <ArticleOutlined fontSize={"medium"} />
            </div>
          )}
          {dataCalon[2] && (
            <div
              className={medsos === "fb" ? "icon-sosial active" : "icon-sosial"}
              onClick={() => setMedsos("fb")}
            >
              <FacebookRounded fontSize={"medium"} />
            </div>
          )}
          {dataCalon[0] && (
            <div
              className={
                medsos === "twitter" ? "icon-sosial active" : "icon-sosial"
              }
              onClick={() => setMedsos("twitter")}
            >
              <Twitter fontSize={"medium"} />
            </div>
          )}
          {dataCalon[1] && (
            <div
              className={medsos === "ig" ? "icon-sosial active" : "icon-sosial"}
              onClick={() => setMedsos("ig")}
            >
              <Instagram fontSize={"medium"} />
            </div>
          )}
        </div>
        <div className={styles.numfound_wrapper}>
          {dataCalon[3] && (
            <div className={styles.numfound}>{dataCalon[3].numFound}</div>
          )}
          {dataCalon[2] && (
            <div className={styles.numfound}>{dataCalon[2].numFound}</div>
          )}
          {dataCalon[0] && (
            <div className={styles.numfound}>{dataCalon[0].numFound}</div>
          )}
          {dataCalon[1] && (
            <div className={styles.numfound}>{dataCalon[1].numFound}</div>
          )}
        </div>
        <div className={styles.content_article}>
          {isLoading && (
            <div className={styles.isLoading}>
              <CircularProgress size={80} />
            </div>
          )}

          {dataCalon[3] &&
            medsos === "article" &&
            dataCalon[3].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[2] &&
            medsos === "fb" &&
            dataCalon[2].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[1] &&
            medsos === "ig" &&
            dataCalon[1].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[0] &&
            medsos === "twitter" &&
            dataCalon[0].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CardCalonMember;
